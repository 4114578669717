@tailwind base;
@tailwind components;
@tailwind utilities;

.sidebar-left-light {
    background-color: #000a16;
}
.sidebar-left-dark {
    background-color: hsl(var(--nextui-background));
}

/* #appContainer {
    background-color: #000a16;
} */

#pageContainer {
    overflow: visible;
    max-width: 1500px;
}

.pagecontainer-light {
    background-color: hsl(var(--nextui-content2));
}
.pagecontainer-dark {
    /* background-color: hsl(var(--nextui-content1)); */
    background-color: #101011;
}

.sidebar-light {
    background-color: #000e1e;
}
.sidebar-dark {
    background-color: hsl(var(--nextui-background));
    /* background-color: #121214; */
}

#sidebar {
    padding-top: 32px;
}

/*
#navbar {
    background-color: #000e1e;
}
*/

html {
    font-size: 16px;
}

html,
body,
body > div:first-child,
div#__next,
div#__next > div,
#pageContent {
    height: 100%;
    overflow: visible;
}
#pageContent, #sidebar {
    padding-bottom: 128px;
}
/* 
#pageContent {
    padding: 16px;
    background-color: hsl(var(--nextui-content2));
} */

#sidebar .sidebar-button {
    color: #dddddd;
    opacity: 0.9;
}
#navbar button {
    min-height: 32px;
    padding: 24px;
}
#navbar .sidebar-category {
    padding: 24px 32px 0px 32px;
}

.sidebar-button, .sidebar-button-current, .sidebar-profile {
    background-color: rgba(0,0,0,0);
    border-radius: 4px;
    /* color: #dddddd; */
    justify-content: flex-start;

    text-transform: uppercase;
    font-size: 11px;
    font-weight: bold;
    letter-spacing: 0.1pt;

    padding: 4px 24px;
}
.sidebar-button svg, .sidebar-button-current svg, .sidebar-profile img {
    font-size: 16px;
    min-width: 24px;
}
.sidebar-profile {
    font-size: 12px;
    text-transform: none;
    color: rgba(255, 255, 255, 0.7); 
    max-height: 32px;
    /* margin-bottom: 64px; */
}
.navbar-light .sidebar-profile {
    color: hsl(var(--nextui-foreground)); 
}
.sidebar-profile img {
    max-height: 24px;
    max-width: 24px;
}
.sidebar-button-current {
    /* background-color: rgba(255, 255, 255, 0.1); */
    
    border-radius: 8px 0px 0px 8px;
    /* color: #fdfdfd; */
}
#sidebar .sidebar-button:hover, .sidebar-profile:hover {
    background-color: rgba(169, 199, 255, 0.05);
}
#sidebar .button-nohover:hover {
    background-color: rgba(169, 199, 255, 0);
}
#sidebar .sidebar-category {
    color: rgba(255, 255, 255, 0.5); 
}

.navbar-light .sidebar-button-current, .sidebar-light .sidebar-button-current {
    background-color: hsl(var(--nextui-content2));
}
.navbar-dark .sidebar-button-current, .sidebar-dark .sidebar-button-current {
    /* background-color: hsl(var(--nextui-content1)); */
    background-color: #101011;
}

.sidebar-category {
    opacity: 0.5;
    text-transform: uppercase;
    font-size: 11px;
    font-weight: bold;
    letter-spacing: 0.1pt;
    padding: 8px;
    display: flex;
    justify-content: center;

    padding: 32px 32px 16px 32px;
    justify-content: flex-start;
}

.input-label {
    text-transform: uppercase;
    font-size: 11px;
    font-weight: bold;
    letter-spacing: 0.1pt;
}

ul.recharts-default-legend:has(span.yAxisLeft) {
    /* display: flex; */
    /* flex-direction: row; */
    /* justify-content: start; */
    text-align: left;
}

.recharts-legend-item:has(span.yAxisLeft) {
    float: left;
    order: 1;
}
.recharts-legend-item:has(span.yAxisRight) {
    float: right;
    margin-right: 0px !important;
    margin-left: 10px;
    order: 2;
}

.recharts-legend-item, .recharts-tooltip-label, .recharts-tooltip-item {
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.1pt;
}

.recharts-tooltip-item-unit {
    padding-left: 4px;
}

.recharts-default-tooltip {
    background-color: hsl(var(--nextui-content1));
    color: hsl(var(--nextui-foreground));
    box-shadow: var(--nextui-box-shadow-medium);
}

.recharts-tooltip-label {
    border-bottom: 1px solid hsl(var(--nextui-content3));
    opacity: 0.8;
}

/* toasts */
.Toastify__toast--success .toastDiv .toast-header {
    color: var(--toastify-icon-color-success);
}
  
.Toastify__toast--error .toastDiv .toast-header {
    color: var(--toastify-icon-color-error);
}

.Toastify .Toastify__toast-body {
    align-items: flex-start;
}

.page-separator-label {
    /* color: rgba(255, 255, 255, 0.5);  */
    opacity: 0.8;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.1pt;
    display: flex;
    justify-content: center;

    padding: 16px 8px 8px 8px;
    justify-content: flex-start;
}

.page-section-header h2 {
    font-size: 28px;
    font-weight: 700;
}

.message-container h4 {
    text-transform: uppercase;
    font-weight: bold;
}

.message-container {
    font-weight: 600;
    font-size: 12px;
    line-height: 22px;
    letter-spacing: 0.1pt;
}

.button-action, .text-input-label, .button-action span {
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.1pt;
    text-transform: uppercase;
}

.button-action svg {
    font-size: 18px;
}

.button-action-tiny {
    font-size: 11px;
    font-weight: 600;
    padding: 0px 2px;
    line-height: 12px;
    max-height: 26px;
    text-transform: uppercase;
}

.simple-list-entry {
    color: hsl(var(--nextui-primary-foreground));
    /* text-transform: uppercase; */
    font-size: 12px;
    font-weight: 600;
}

.button-action-tiny-inline {
    font-size: 16px;
    font-weight: 600;
    padding: 2px 4px;
    line-height: 14px;
    max-height: 24px;
    max-width: 24px;
    min-width: 24px;
    color: hsl(var(--nextui-primary-foreground));
}

.button-action-tiny svg {
    font-size: 12px;
}

.text-input-field {
    z-index: 5;
    /* background-color: hsl(var(--nextui-background)); */
}

.text-input-label {
    font-size: 11px;
    font-weight: 700;
    position: relative;
}

.button-input-text-actions button {
    padding: 0px 6px 0px 6px;
    margin: 0 4px;
    max-width: auto;
    max-height: 18px;
    line-height: 10px;
    font-size: 10px;
    font-weight: 700;
    text-transform: uppercase;
}

.dropdown-test ul {
    max-height: 480px;
    overflow-y: scroll;
}

.button-input-text-actions {
    z-index: 2;
    position: absolute;
    top: 0px;
    right: 0px;
}

.card-header-button {
    font-size: 11px;
    font-weight: 600;
    margin: 0;
    text-transform: uppercase;
}

.card-header-button svg {
    font-size: 14px;
}

.card-header-panel {
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.1pt;
    text-transform: uppercase;
    padding: 8px 8px 8px 14px;
}

/* range slider */
.range-slider .range-slider__thumb {
    background: hsl(var(--nextui-content1));
    border: 5px solid hsl(var(--nextui-primary));
  }
  
  .range-slider .range-slider__range {
    background: hsl(var(--nextui-primary));
    border-radius: 3px;
  }
  
  .single-thumb .range-slider__thumb[data-lower] {
    width: 0;
    border-style: hidden;
  }
  
  .range-slider {
    touch-action: none;
    -webkit-tap-highlight-color: transparent;
    -webkit-user-select: none;
    user-select: none;
    cursor: pointer;
    display: block;
    position: relative;
    width: 100%;
    height: 6px;
    background: hsl(var(--nextui-content3));
    border-radius: 4px;
  }
  .range-slider[data-vertical] {
    height: 100%;
    width: 8px;
  }
  .range-slider[data-disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }
  .range-slider .range-slider__thumb {
    position: absolute;
    z-index: 3;
    top: 50%;
    width: 22px;
    height: 22px;
    transform: translate(-50%, -50%);
    border-radius: 50%;
  }
  .range-slider .range-slider__thumb:focus-visible {
    outline: 0;
    box-shadow: 0 0 0 6px rgba(33, 150, 243, 0.5);
  }
  .range-slider[data-vertical] .range-slider__thumb {
    left: 50%;
  }
  .range-slider .range-slider__thumb[data-disabled] {
    z-index: 2;
  }
  .range-slider .range-slider__range {
    position: absolute;
    z-index: 1;
    transform: translate(0, -50%);
    top: 50%;
    width: 100%;
    height: 100%;
  }
  .range-slider[data-vertical] .range-slider__range {
    left: 50%;
    transform: translate(-50%, 0);
  }
  .range-slider input[type="range"] {
    -webkit-appearance: none;
    pointer-events: none;
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    background-color: transparent;
  }
  .range-slider input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
  }
  .range-slider input[type="range"]::-moz-range-thumb {
    width: 0;
    height: 0;
    border: 0;
  }
  .range-slider input[type="range"]:focus {
    outline: 0;
  }

.modal-icon svg {
    font-size: 18px;
}

@keyframes savebutton-slide-in {
    100% { transform: translateY(0%); }
}
@keyframes savebutton-slide-out {
    100% { transform: translateY(200%); }
}
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
.minispinner {
    animation-name: spin;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}
.input-tiny-text, .input-tiny-text textarea {
    font-size: 12px;
}
.textarea-nowrap textarea {
    font-family: monospace;
    white-space: pre;
    overflow-wrap: normal;
    overflow-x: scroll;
    resize: vertical;
    min-height: 80px;
    scrollbar-width: thin !important;
}
.scrollbar-thin {
    scrollbar-width: thin !important;
}
.service-status-detail a {
    text-decoration: underline;
}

/* media additions */
@media (max-width: 576px) {
    .text-input-label {
        font-size: 9px;
    }
    .text-xs {
        font-size: 0.7rem;
        line-height: 1rem;
    }
    .text-sm {
        font-size: 0.8rem;
        line-height: 1.1rem;
    }
    .text-md {
        font-size: 1rem;
        line-height: 1.25rem;
    }
    
    /* .button-action {
        padding: 4px 8px;
        max-height: 36px;
    } */
    /* div[data-slot="main-wrapper"] {
        max-height: 20px;
    } */
}